<template>
  <v-card>
    <v-card-title class="justify-center mb-2">
      Registrar
    </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="leaderIdSelected"
              :items="leaderList"
              :rules="[rules.required]"
              item-text="name"
              item-value="id"
              label="Destinatário"
              clearable
              outlined
              dense
            />

            <v-autocomplete
              v-model="administratorIdSelected"
              :items="leaderList"
              :rules="[rules.required]"
              item-text="name"
              item-value="id"
              label="Gerente regional"
              clearable
              outlined
              dense
            />
          </v-col>

          <v-col>
            <v-dialog
              ref="dialogResolutionDeadline"
              v-model="showCalendarResolutionDeadLine"
              :return-value.sync="resolutionDeadline"
              width="400px"
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedResolutionDeadline"
                  :append-icon="icons.mdiCalendar"
                  :rules="[rules.required]"
                  label="Prazo da resolução"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                />
              </template>

              <v-date-picker
                v-model="resolutionDeadline"
                width="400px"
                locale="pt-BR"
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="showCalendarResolutionDeadLine = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialogResolutionDeadline.save(resolutionDeadline)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>

            <v-dialog
              ref="dialogInconsistency"
              v-model="showCalendarInconsistency"
              :return-value.sync="inconsistencyDate"
              width="400px"
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedInconsistencyDate"
                  :append-icon="icons.mdiCalendar"
                  :rules="[rules.required]"
                  label="Data da inconsistência"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                />
              </template>

              <v-date-picker
                v-model="inconsistencyDate"
                width="400px"
                locale="pt-BR"
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="showCalendarInconsistency = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialogInconsistency.save(inconsistencyDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <v-text-field
          v-model="processName"
          :rules="[rules.required]"
          label="Processo"
          outlined
          dense
        />

        <v-text-field
          v-model="emailCC"
          label="Email para copia"
          outlined
          dense
        />

        <v-file-input
          v-model="fileList"
          :append-icon="icons.mdiPaperclip"
          prepend-icon=""
          label="Anexos"
          outlined
          multiple
          chips
          dense
        />

        <vue-editor v-model="description" />
      </v-form>

      <div class="d-flex justify-end mt-5">
        <v-btn
          color="error"
          class="mr-5"
          @click="closeModal(), resetForm()"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="info"
          @click="sendModalData()"
        >
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import utilsMixin from '@/plugins/utilsMixin'
import { mdiCalendar, mdiPaperclip } from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor,
  },

  mixins: [formatters, messages, utilsMixin],

  data() {
    return {
      dataId: '',
      emailCC: '',
      description: '',
      processName: '',
      inconsistencyDate: '',
      resolutionDeadline: '',

      isLoading: false,
      showCalendarInconsistency: false,
      showCalendarResolutionDeadLine: false,

      fileList: [],
      leaderList: [{}],
      bodyArrayEmpty: [],
      leaderIdSelected: '',
      administratorIdSelected: '',

      icons: {
        mdiPaperclip,
        mdiCalendar,
      },
    }
  },

  computed: {
    computedInconsistencyDate: {
      get() {
        return this.formatDate(this.inconsistencyDate)
      },

      set() {},
    },

    computedResolutionDeadline: {
      get() {
        return this.formatDate(this.resolutionDeadline)
      },

      set() {},
    },
  },

  created() {
    this.leaderList = localStorageSlim.get('leaderList', { decrypt: true })
  },

  methods: {
    async sendModalData() {
      const formIsValid = this.$refs.form.validate()
      const {
        description, resolutionDeadline, inconsistencyDate, processName, emailCC, leaderIdSelected, administratorIdSelected,
      } = this

      const body = {
        text: description.toUpperCase(),
        solution_date: resolutionDeadline,
        date_inconsistency: inconsistencyDate,
        process_name: processName,
        email_cc: this.extractEmailsFromString(emailCC),
        users_responsible_id: leaderIdSelected,
        users_leader_id: administratorIdSelected,
      }

      if (!formIsValid) {
        this.showMessage({
          title: 'Campos vazios!',
          text: 'Verifique se todos os campos estão preenchidos',
        })

        return
      }

      this.isLoading = true

      await axiosIns
        .post('api/v1/ri/store', body)
        .then(response => {
          const { id } = response.data.data

          this.sendFiles(id)

          this.showMessage({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
          })

          this.updatedTable()
          this.resetForm()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error,
          })
        })
        .finally(() => {
          this.isLoading = false
          this.closeModal()
        })
    },

    /**
     * @param {string} id
     */
    async sendFiles(id) {
      const formData = new FormData()

      this.fileList.push(this.dataId)
      this.fileList.forEach(file => {
        formData.append('files[]', file)
        formData.append('id', id)
      })

      await axiosIns.post('api/v1/attachment/submit_files', formData).then(() => this.resetForm())
    },

    /**
     * Brazilian standard date format
     *
     * @param {string} date
     * @returns {string}
     */
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },

    resetForm() {
      this.$refs.form.reset()
    },
  },
}
</script>

<style scoped>
/* Retira o incrementador padrão dos inputs do tipo "number"*/
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
