<template>
  <div>
    <v-card class="mb-6">
      <v-card-text class="d-flex">
        <div class="container-content">
          <div class="container-ball-success"></div>
          <span class="ml-2">Aberto</span>
        </div>

        <div class="container-content">
          <div class="container-ball-warning"></div>
          <span class="ml-2">Acompanhamento</span>
        </div>

        <div class="container-content">
          <div class="container-ball-answered"></div>
          <span class="ml-2">Respondido</span>
        </div>

        <div class="container-content">
          <div class="container-ball-error"></div>
          <span class="ml-2">Fechado</span>
        </div>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title class="pt-0 pb-0">
        <span class="mr-6"> Atendimentos </span>

        <v-text-field
          v-model="filterData"
          class="mt-7"
          label="Buscar por Nº. RI, Nome, Status, Filial "
          outlined
          dense
          @keyup.enter="findFilterResult"
        />

        <v-btn
          class="mt-1 ml-4"
          outlined
          @click="clearFilter()"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiFilterRemoveOutline }}
          </v-icon>
          Limpar filtros
        </v-btn>

        <v-spacer />

        <v-btn
          color="warning"
          class="ml-4 mt-1"
          outlined
          @click="exportCsv()"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiDownloadBox }}
          </v-icon>
          Exportar
        </v-btn>

        <v-btn
          color="info"
          class="mt-1 ml-4"
          outlined
          @click="openModal('create')"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          Novo
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="listOfFilteredItems"
        :loading="isLoading"
        loading-text="Carregando dados..."
      >
        <template v-slot:no-data>
          <span>Sem informações para exibir</span>
        </template>

        <template v-slot:item.status_name="{ item }">
          <v-alert
            class="badge"
            :color="getStatusColor(item.status_name)"
            text
          >
            {{ item.status_name }}
          </v-alert>
        </template>

        <template v-slot:item.solution_date="{ item }">
          {{ dateFormat(item.solution_date) }}
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ dateFormat(item.created_at, true) }}
        </template>

        <template v-slot:item.options="{ item }">
          <v-icon
            color="secondary"
            @click="openModal('details', item)"
          >
            {{ icons.mdiEye }}
          </v-icon>

          <v-icon
            color="warning"
            medium
            @click="openModal('history', item)"
          >
            {{ icons.mdiClipboardTextOutline }}
          </v-icon>
        </template>
      </v-data-table>

      <v-dialog
        v-model="showModal"
        width="700px"
        persistent
      >
        <internalComplaintModal
          @updatedTable="updatedTable()"
          @close="showModal = false"
        ></internalComplaintModal>
      </v-dialog>

      <v-dialog
        v-model="showDetails"
        width="700px"
        persistent
      >
        <internalComplaintDetails
          :key="dataDetails.id"
          :data="dataDetails"
          @updatedTable="updatedTable()"
          @close="showDetails = false"
        ></internalComplaintDetails>
      </v-dialog>

      <v-dialog
        v-model="showHistory"
        width="700px"
        persistent
      >
        <History
          :key="dataDetails.id"
          :item-id="dataDetails.id"
          @close="showHistory = false"
        ></History>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiClipboardText,
  mdiClipboardTextOutline,
  mdiDownloadBox,
  mdiEye,
  mdiFilterPlusOutline,
  mdiFilterRemoveOutline,
  mdiNewspaperPlus,
  mdiPlaylistPlus,
} from '@mdi/js'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import localStorageSlim from 'localstorage-slim'
import History from './History.vue'
import internalComplaintDetails from './internalComplaintDetails.vue'
import internalComplaintModal from './internalComplaintModal.vue'

export default {
  components: {
    internalComplaintModal,
    internalComplaintDetails,
    History,
  },

  mixins: [formatters, messages],

  data() {
    return {
      filterData: '',

      headers: [
        {
          text: 'Nº. RI',
          value: 'protocol_number',
          sortable: false,
          align: 'center',
        },
        {
          text: 'EMISSOR',
          value: 'user_requester.occupation_sector.sector.name',
          sortable: true,
          align: 'center',
        },
        {
          text: 'LOJA',
          value: 'user_responsible.company.fantasy_name',
          sortable: true,
          align: 'center',
        },
        {
          text: 'DEPARTAMENTO',
          value: 'user_responsible.occupation_sector.sector.name',
          sortable: true,
          align: 'center',
        },
        {
          text: 'DATA DE EMISSÃO',
          value: 'created_at',
          sortable: false,
          align: 'center',
        },
        {
          text: 'PRAZO',
          value: 'solution_date',
          sortable: true,
          align: 'center',
        },
        {
          text: 'STATUS',
          value: 'status_name',
          sortable: true,
          align: 'center',
        },
        {
          text: 'OPÇÕES',
          value: 'options',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],
      dataDetails: { id: '' },
      listOfFilteredItems: [],

      isLoading: false,
      showModal: false,
      showDetails: false,
      showHistory: false,

      icons: {
        mdiEye,
        mdiDownloadBox,
        mdiPlaylistPlus,
        mdiNewspaperPlus,
        mdiClipboardText,
        mdiFilterPlusOutline,
        mdiFilterRemoveOutline,
        mdiClipboardTextOutline,
      },
      loadingUpdateStatus: false,
    }
  },

  watch: {
    filterData() {
      this.findFilterResult()
    },
  },

  created() {
    this.getItemsTable()

    const userProfiles = localStorageSlim.get('userProfile', { decrypt: true })

    this.userProfile = userProfiles
  },

  methods: {
    getItemsTable() {
      this.isLoading = true

      axiosIns
        .get('api/v1/ri/index')
        .then(response => {
          const { data } = response.data

          this.itemsTable = data
          this.listOfFilteredItems = data
        })
        .catch(error => {
          this.showErrorMessage(error)
        })
        .finally(() => { this.isLoading = false })
    },

    findFilterResult() {
      const { filterData, itemsTable } = this

      this.listOfFilteredItems = itemsTable.filter(
        ({
          status_name: statusName, user_requester: userRequester, user_responsible: userResponsible, protocol_number: protocol,
        }) => (statusName.toLowerCase().includes(filterData.toLowerCase())
          || userRequester?.name.toLowerCase().includes(filterData.toLowerCase())
          || userResponsible?.name.toLowerCase().includes(filterData.toLowerCase())
          || userResponsible.occupation_sector?.sector?.name.toLowerCase().includes(filterData.toLowerCase())
          || String(protocol).includes(filterData)
          || userResponsible.company?.fantasy_name?.toLowerCase().includes(filterData.toLowerCase())
        ),
      )
    },

    /**
     * Returns the corresponding color of a status value for display.
     *
     * @param {string} status - The status value to get the color for.
     * @return {string} - The corresponding color of the status value.
     */
    getStatusColor(status) {
      const statusMap = { ABERTO: 'success', ACOMPANHAMENTO: 'warning', FECHADO: 'error' }

      return statusMap[status] ?? ''
    },

    updatedTable() {
      this.getItemsTable()
    },

    openModal(type, data = {}) {
      const requestingUser = data.user_requester?.name
      const loggedInUser = this.userProfile?.name
      const verifyRequestingRI = requestingUser === loggedInUser

      if (data.status_name === 'RESPONDIDO' && verifyRequestingRI) {
        this.loadingUpdateStatus = true

        try {
          axiosIns.put(`/api/v1/ri/update/${data.id}`, { status_name: 'ACOMPANHAMENTO' })
        } catch (error) {
          this.showErrorMessage(error)
        } finally {
          this.loadingUpdateStatus = false
          this.updatedTable()
        }
      }

      const modal = {
        create: () => { this.showModal = true },
        details: () => { this.showDetails = true },
        history: () => { this.showHistory = true },
      }

      if (type in modal) {
        modal[type]()
        this.dataDetails = data

        return
      }

      console.error(`Invalid modal type: ${type}`)
    },

    clearFilter() {
      this.filterData = ''
      this.updatedTable()
    },

    async exportCsv() {
      try {
        const file = await this.getCsv()
        if (file) saveAs(file)
      } catch (error) {
        this.showErrorMessage(error)
      }
    },

    async getCsv() {
      const { listOfFilteredItems, headers } = this
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('RI')
      const filteredItems = [...listOfFilteredItems]
      const headerWithoutAction = headers.slice(0, -1)

      worksheet.columns = headerWithoutAction.map(({ text, value }) => ({ header: text, key: value }))

      filteredItems.forEach(item => {
        // eslint-disable-next-line camelcase
        const { user_responsible, user_requester } = item

        const itemWithFormattedDates = {
          ...item,
          'user_requester.occupation_sector.sector.name': user_requester?.occupation_sector?.sector?.name,
          'user_responsible.company.fantasy_name': user_responsible?.company.fantasy_name,
          'user_responsible.occupation_sector.sector.name': user_responsible?.occupation_sector?.sector?.name,
          created_at: this.dateFormat(item.created_at),
          solution_date: this.dateFormat(item.solution_date, true),
        }

        worksheet.addRow(itemWithFormattedDates)
      })

      const buffer = await workbook.xlsx.writeBuffer()
      const file = new File([buffer], 'RI.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

      return file
    },
  },
}
</script>

<style scoped>
.badge {
  margin-top: 16px;
  font-size: 13px;
  height: 40px;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 2.7778rem;
}
.container-ball-success {
  background: #56ca00;
  width: 0.5556rem;
  height: 0.5556rem;
  border-radius: 0.8333rem;
}
.container-ball-warning {
  background: #ffb400;
  width: 0.5556rem;
  height: 0.5556rem;
  border-radius: 0.8333rem;
}
.container-ball-error {
  background: #ff4c52;
  width: 0.5556rem;
  height: 0.5556rem;
  border-radius: 0.8333rem;
}

.container-ball-answered{
  background: #928DA9;
  width: 0.5556rem;
  height: 0.5556rem;
  border-radius: 0.8333rem;
}
</style>
